import { useEffect } from "react";
import { ScrollArea } from "../ui/scroll-area";
import ModalContainer from "./ModalContainer";
import { Link } from "react-router-dom";

type Props = {
  open: boolean;
  close: () => void;
};

const CalendlyScheduleModal = ({ open, close }: Props) => {
  useEffect(() => {
    document.addEventListener(
      "keydown",
      (e) => e.key === "Escape" && close && close()
    );

    return () => {
      document.removeEventListener("keydown", (e) => e.key === "Escape");
    };
  }, []);

  return (
    <div className="w-full">
      <ModalContainer open={open} close={close}>
        <ScrollArea className="p-3 md:p-5 w-[90vw] md:w-[55vw] h-[45vh]">
          <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              <img src="/assets/athlete-training-outdoors.svg" />
            </div>
            <div className="w-full md:w-1/2 h-fit flex flex-col space-y-6 justify-center">
              <p className="font-bold text-lg text-black">
                Proceed by booking a fun interview chat with us now.
              </p>
              <p className="text-gray-500">
                Upon completion of the next stage, you would have no need to
                search for contract jobs.Companies will rather apply to you.
                Thank you!
              </p>
              <a
                target="_blank"
                href={process.env.REACT_APP_CALENDLY_LINK ?? ''}
                className="inline-flex justify-center w-fit px-5 py-2 font-semibold text-gray-100 transition-colors duration-200 transform bg-[#001935] rounded-md hover:bg-white hover:text-[#001935] hover:border-2 hover:border-solid hover:border-[#001935] cursor-pointer"
              >
                Schedule Interview
              </a>
            </div>
          </div>
        </ScrollArea>
      </ModalContainer>
    </div>
  );
};

export default CalendlyScheduleModal;
