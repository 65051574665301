import { Dispatch, ThunkDispatch, UnknownAction, createSlice } from "@reduxjs/toolkit";
import { TOKEN } from "src/App";
import { RootState } from "src/lib/store/store";
import { AuthState } from "./auth.type";

const initialState: AuthState = {
  user: null,
  token: null,
  accessToken: null,
  isAuthenticated: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    addUserProfile: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      state.user = payload.user;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addMatcher(
  //       authApi.endpoints.getUserProfile.matchPending,
  //       (state, action) => {
  //         // console.log("pending", action);
  //       }
  //     )
  //     .addMatcher(
  //       authApi.endpoints.getUserProfile.matchFulfilled,
  //       (
  //         state,
  //         action: PayloadAction<{
  //           user: UserProfile;
  //           accessToken: string;
  //         }>
  //       ) => {
  //         // console.log("fulfilled", action);
  //         state.user = action.payload.user;
  //         state.token = null;
  //         state.accessToken = action.payload.accessToken;
  //         state.isAuthenticated = true;
  //       }
  //     )
  //     .addMatcher(
  //       authApi.endpoints.getUserProfile.matchRejected,
  //       (state, action) => {
  //         // console.log("rejected", action);
  //       }
  //     );
  // },
});

export const { logout, addUserProfile } = slice.actions;
export default slice.reducer;

export const selectUserState: (state: RootState) => AuthState = (
  state: RootState
) => state.auth;
