import { AxiosError } from "axios";
import axiosInstance from "src/config/axios";
import useSWR from "swr";
import { Bank, TransactionsResponse } from "./wallet.type";
import useSWRMutation from "swr/mutation";

export const useTransactionsQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    TransactionsResponse,
    AxiosError,
    `/dev/getAllMyTransactions`
  >(`/dev/getAllMyTransactions`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data?.data)
  );

  return {
    transactions: data,
    isLoading,
    error,
    getTransactions: mutate,
  };
};

export const useTransactionsDetailsQuery = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR<
    any,
    AxiosError,
    string
  >(`/dev/getATransactionById/${id}`, (url: string) =>
    // `/dev/getATransactionById`
    axiosInstance.get(url).then((res) => res.data?.data)
  );

  return {
    transaction: data,
    isLoading,
    error,
    getTransaction: mutate,
  };
};

export const useBanksQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    Bank[],
    AxiosError,
    `/all/getAllBanks`
  >(`/all/getAllBanks`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data?.data)
  );

  return {
    banks: data,
    isLoading,
    error,
    getBanks: mutate,
  };
};

export const useWithdrawMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    any,
    AxiosError,
    string,
    any,
    any
  >(`/dev/withdrawFunds`, (url: string, { arg }) =>
    axiosInstance.post(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    withdraw: trigger,
  };
};
