'use client';

type Props = {
  title: string
}

const Heading = ({ title }: Props) => {
  return (
    <div className={'w-full'}>
      <p className="text-black text-xl font-semibold w-fit mx-auto">
        {title}
      </p>
    </div>
  );
};

export default Heading;
