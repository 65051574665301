import { Fragment, memo, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { IoIosAlert, IoIosCheckmarkCircle } from "react-icons/io";

export type AlertVariant = "info" | "warn" | "success" | "error";

export type ALERT = {
  title: string;
  variant: AlertVariant;
  onClose?: () => void;
  active: boolean;
};

type Props = {
  alert: ALERT;
};

const Alert = memo(function ({
  alert: { title, variant, onClose, active },
}: Props) {
  const borderColor =
    variant === "success"
      ? "border-green-300"
      : variant === "info"
      ? "border-blue-300"
      : variant === "error"
      ? "border-red-300"
      : "border-yellow-300";

  const bgColor =
    variant === "success"
      ? "bg-green-100"
      : variant === "info"
      ? "bg-blue-100"
      : variant === "error"
      ? "bg-red-100"
      : "bg-yellow-100";

  const textColor =
    variant === "success"
      ? "!text-green-500"
      : variant === "info"
      ? "!text-blue-500"
      : variant === "error"
      ? "!text-red-500"
      : "!text-yellow-500";

  const icon =
    variant === "error" || variant === "warn" ? (
      <IoIosAlert className={`h-8 w-8`} />
    ) : variant === "success" ? (
      <IoIosCheckmarkCircle className={`h-8 w-8`} />
    ) : (
      <IoIosCheckmarkCircle className={`h-8 w-8`} />
    );

  useEffect(() => {
    if (title === "") return;

    if (variant === "success") {
      toast.success(title, { className: "!text-green-500 !bg-gray-700" });
    } else if (variant === "error") {
      toast.error(title, { className: "!text-red-500 !bg-gray-700" });
    } else {
      toast.info(title, { className: "!text-blue-500 !bg-gray-700" });
    }
  }, [title, variant]);

  return (
    <Fragment>
      {active && title !== "" ? (
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      ) : null}
    </Fragment>
  );
});

Alert.displayName = "Alert";

export default Alert;
