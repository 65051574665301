import { TOKEN } from "src/App";
import { logout } from "src/lib/features/auth/authSlice";
import { useAppDispatch } from "src/lib/store/store";

const useHandleLogout = () => {
  const dispatch = useAppDispatch();

  const handleLogout = (callback?: () => void) => {
    dispatch(logout());
    localStorage.removeItem(TOKEN);
    callback && callback();
    console.log(
      "process.env.REACT_APP_BASE_WEB_URL",
      process.env.REACT_APP_BASE_WEB_URL
    );
    window.location.href = process.env.REACT_APP_BASE_WEB_URL ?? "";
  };

  return {
    handleLogout,
  };
};

export default useHandleLogout;
