import React from "react";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

type Props = {
  label?: string;
  className?: string;
  onClick?: (e?: any) => void;
  type?: "button" | "submit" | "reset" | undefined;
};

const OutlineButton = ({ label, className, onClick, type }: Props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <button
      type={type}
      className={`${className} ${
        theme === "dark"
          ? "border-white text-white "
          : "border-primary text-primary "
      } text-sm rounded-lg px-[30px] py-3.5 border font-semibold`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default OutlineButton;
