import axios from "axios";
import { store } from "src/lib/store/store";

const headers = {};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL + "/api/v1",
  timeout: 20000,
  headers,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth?.accessToken ?? "";

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
