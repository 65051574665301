import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SWRConfig } from "swr";
import { Provider } from "react-redux";
import { store } from "./lib/store/store";
// import { PersistGate } from 'redux-persist/integration/react';
// import PageLoader from "./components/Loader/PageLoader";

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register(new URL("./firebase-messaging-sw.js", import.meta.url), {
//       type: "module",
//     })
//     .then(
//       function (response) {
//         // Service worker registration done
//         console.log("Registration Successful", response);
//       },
//       function (error) {
//         // Service worker registration failed
//         console.log("Registration Failed", error);
//       }
//     );
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        onError: (error, key) => {
          if (error.status !== 403 && error.status !== 404) {
            // console.log('global_error', error)
            // We can send the error to Sentry,
            // or show a notification UI.
          }
        },
      }}
    >
      <Provider store={store}>
        {/* <PersistGate loading={<PageLoader />} persistor={persistor}> */}

        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </PersistGate> */}
      </Provider>
    </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
