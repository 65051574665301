import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PageIntro from "src/components/PageIntro/PageIntro";
import Pagination from "src/components/Pagination";
import Status from "src/components/Status/Status";
import Table from "src/components/Table/Table";
import useAlert from "src/hooks/use-alert";
import { useActiveProjectQuery } from "src/lib/endpoints/job/job.api";

const ActiveProjects = () => {
  const { pathname } = useLocation();
  const siblingCount = 1;
  const pageColumn = 1;
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const { activeProjects, isLoading } = useActiveProjectQuery(
    `?page=${page + 1}`
  );
  const [totalCount, setTotalCount] = useState(0);
  const { state, open, close, Alert } = useAlert();

  const handlePageChange = useCallback(
    (_page: number) => {
      setPage(_page - 1);
    },
    [page]
  );

  const tableHeader = useMemo(
    () => [
      {
        title: "Client",
        key: "client",
      },
      {
        title: "Project",
        key: "project",
      },
      {
        title: "Date",
        key: "date",
      },
      {
        title: "Status",
        key: "status",
      },
      {
        title: "",
        key: "details",
      },
    ],
    []
  );

  const modifiedData = useMemo(() => {
    return activeProjects?.data?.map((each) => [
      each?.projectDetailJobDetail?.companyName ?? "",
      each?.projectDetailJobDetail?.jobTitle ?? "",
      format(each?.startDate ?? "", "EEE, MMM d"),
      <Status label={each?.status} />,
      <Link
        className="inline-block rounded-md py-[2px] px-3 border border-[#575555] text-[#8D8989]"
        to={`/project/${each.job_detail_id}`}
      >
        Details
      </Link>,
    ]);
  }, [activeProjects]);
  
  useEffect(() => {
    setTotalCount(activeProjects?.total ?? 0);
  }, [activeProjects]);

  if (!modifiedData) return null;

  return (
    <div>
      {!pathname.includes("projects") ? (
        <div className="flex justify-end">
          <Link
            to={"/projects"}
            className="underline underline-offset-2 font-medium my-2 w-fit"
          >
            See all
          </Link>
        </div>
      ) : (
        <>
          <h1 className="text-sm font-bold leading-8 mb-20 md:mb-6 text-left">
            {`Dashboard > Projects`}
          </h1>

          <div className="mb-10 lg:mb-12">
            <PageIntro title={"Projects"} description={""} />
          </div>
        </>
      )}
      <Table header={tableHeader} label="Work History" column={modifiedData} />

      <section className="w-full py-5">
        <Pagination
          rangeStart={
            totalCount !== 0
              ? (page || 0) * ((pageSize || 0) * pageColumn) + 1
              : 0
          }
          totalCount={totalCount}
          currentPage={(page || 0) + 1}
          pageSize={pageSize}
          pageColumn={pageColumn}
          siblingCount={siblingCount}
          onPageChange={handlePageChange}
          className="pt-9"
        />
      </section>

      <span className="fixed right-3 top-3 z-30">
        <Alert />
      </span>
    </div>
  );
};

export default ActiveProjects;
