import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PageIntro from 'src/components/PageIntro/PageIntro';
import Status from 'src/components/Status/Status';
import { useTransactionsQuery } from 'src/lib/endpoints/wallet/wallet.api';
import { startCase } from 'src/utils/utils';
import WalletTransactionDetailModal from '../../components/Modal/WalletTransactionDetailModal';
import Table from '../../components/Table/Table';

const TransactionTable = () => {
  const { transactions } = useTransactionsQuery()
  const [selectedData, setSelectedData] = useState()
  const { pathname } = useLocation();

  const tableHeader = useMemo(
    () => [
      {
        title: 'Type',
        key: 'type',
      },
      {
        title: 'Amount',
        key: 'amount',
      },
      {
        title: 'Date',
        key: 'date',
      },
      {
        title: 'Status',
        key: 'status',
      },
    ],
    []
  );

  const modifiedData = useMemo(() => {
    return transactions?.map((each) => [startCase(each?.type) ?? '', each?.amount ?? '0', format(each?.created_at ?? new Date().getTime(), "EEE, MMM d"), <Status label={each?.status ?? ''} />,
    <div
      className='inline-block rounded-md py-[2px] px-3 border border-[#575555] text-[#8D8989]'
      onClick={() => setSelectedData(each)}
    >
      Details
    </div>]);
  }, [transactions]);

  if (!modifiedData) return null

  return (
    <div>
      {!pathname.includes("transactions") ? (
        <div className="flex justify-end">
          <Link
            to={"/transactions"}
            className="underline underline-offset-2 font-medium my-2 w-fit"
          >
            See all
          </Link>
        </div>
      ) : (
        <>
          <h1 className="text-sm font-bold leading-8 mb-20 md:mb-6 text-left">
            {`Dashboard > All transaction`}
          </h1>

          <div className="mb-10 lg:mb-12">
            <PageIntro title={"Wallet Trnsactions"} description={""} />
          </div>
        </>
      )}

      <Table
        header={tableHeader}
        label={!pathname.includes("transactions") ? 'Recent Transaction' : ''}
        column={modifiedData}
      />

      {!!selectedData && <WalletTransactionDetailModal open={!!selectedData} close={() => setSelectedData(undefined)} data={selectedData} />}
    </div>
  );
};

export default TransactionTable;
