import { AxiosError } from "axios";

import axiosInstance from "src/config/axios";
import useSWRMutation from "swr/mutation";

export const useUserProfileMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    { user: any; accessToken: string },
    AxiosError,
    "/all/getUserProfile",
    FormData,
    any
  >("/all/getUserProfile", (url: string, { arg }) =>
    axiosInstance.post(url, arg).then((res) => ({
      accessToken: arg.get("userToken") as string,
      user: res.data,
    }))
  );

  return {
    profile: data,
    isLoading: isMutating,
    getProfile: trigger,
  };
};

export const usePushNotificationMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    { user: any; accessToken: string },
    AxiosError,
    "/all/push-subscription",
    FormData,
    any
  >("/all/push-subscription", (url: string, { arg }) =>
    axiosInstance.post(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    subscribe: trigger,
  };
};