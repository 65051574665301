'use client';

import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { DOTS, usePagination } from 'src/hooks/use-pagination';
import { useIsClient } from 'usehooks-ts';
import { v4 } from 'uuid';



type Props = {
  rangeStart: number
  totalCount: number
  currentPage: number
  siblingCount: number
  pageSize?: number
  className?: string
  pageColumn?: number
  onPageChange?: (x: number) => void
}

const Pagination = ({
  rangeStart,
  totalCount,
  currentPage,
  siblingCount,
  pageSize = 10,
  pageColumn = 1,
  className,
  onPageChange,
}: Props) => {
  const isClient = useIsClient()
  const paginationRange = usePagination({
    currentPage,
    totalCount: totalCount / pageColumn,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange && onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange && onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  if (!isClient) return null

  return (
    <div className={className}>
      <div className="flex items-center justify-center space-x-2 pb-4 lg:pb-0">
        <button
          className="text-shade-medium disabled:text-gray-400"
          disabled={currentPage === 1}
          onClick={onPrevious}
        >
          <MdOutlineKeyboardArrowLeft className="h-7 w-7 md:h-9 md:w-9" />
        </button>

        {paginationRange &&
          paginationRange.map(pageNumber => {
            if (pageNumber === DOTS) {
              return (
                <span key={v4()} className="flex h-full w-fit items-center">
                  ...
                </span>
              )
            }

            return (
              <button
                key={pageNumber}
                onClick={() => onPageChange && onPageChange(Number(pageNumber))}
                className={`rounded-full border-none px-2 py-1 text-sm md:px-4 md:py-2 ${
                  pageNumber === currentPage
                    ? 'bg-primary-3 text-white bg-slate-500 hover:bg-primary-3/60'
                    : 'bg-gray-100 hover:bg-gray-200'
                } transition-ease`}
              >
                {pageNumber}
              </button>
            )
          })}

        <button
          className="text-shade-medium disabled:text-gray-400"
          disabled={currentPage === lastPage}
          onClick={onNext}
        >
          <MdOutlineKeyboardArrowRight className="h-7 w-7 md:h-9 md:w-9" />
        </button>
      </div>
    </div>
  )
}

export default Pagination