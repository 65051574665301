import { FaChevronDown } from 'react-icons/fa';
import { selectUserState } from 'src/lib/features/auth/authSlice';
import { useTypedSelector } from 'src/lib/store/store';

const UserInfo = () => {
  const user = useTypedSelector(selectUserState).user

  return (
    <div className="flex items-center space-x3">
        <div className={`relative w-[60px] h-[60px]`}>
          <img
            src={user?.photo ?? ""} //'https://tecdn.b-cdn.net/img/new/avatars/2.webp'
            className="rounded-full w-full h-full"
            alt={user?.firstName + " " + user?.lastName}
          />
      </div>
      <div>
        <div className="flex items-center gap-2 font-semibold">
          <p
            className={`text-left text-lg leading-8 font-semibold text-[#8D8989]`}
          >
            {user?.firstName + " " + user?.lastName}
          </p>
          <FaChevronDown />
        </div>
        {/* <p
          className={`text-sm
           text-left text-[#8D8989] whitespace-nowrap`}
        >
          Coupon Earnings: $23
        </p> */}
      </div>
    </div>
  );
};

export default UserInfo;
