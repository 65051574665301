import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import auth from "../features/auth/authSlice";

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      // [api.reducerPath]: api.reducer,
      auth,
    },
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware().concat(api.middleware),
    ...options,
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;







// import {
//   combineReducers,
//   configureStore,
//   ConfigureStoreOptions,
// } from "@reduxjs/toolkit";
// import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import { persistReducer, persistStore } from "redux-persist";
// import { api } from "../api-services/api";
// // import { persistedReducer } from "./persistConfig";
// import storage from "redux-persist/lib/storage";
// import authSlice from "../features/auth/authSlice";

// const authPersistConfig = {
//   key: "auth",
//   storage,
// };

// const rootReducer = combineReducers({
//   api: persistReducer(authPersistConfig, api.reducer),
//   auth: authSlice,
// });

// export const Redux_Persist_key = "talent-root";

// const persistConfig = {
//   key: Redux_Persist_key,
//   storage,
// };

// export const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const createStore = (
//   options?: ConfigureStoreOptions["preloadedState"] | undefined
// ) =>
//   configureStore({
//     reducer: persistedReducer,
//     middleware: (getDefaultMiddleware) =>
//       getDefaultMiddleware().concat(api.middleware),
//     ...options,
//   });

// export const store = createStore();
// export const persistor = persistStore(store);

// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch;
// export type RootState = ReturnType<typeof store.getState>;
// export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
