import React from 'react';

const Icon = ({ width, height, name, color, size, ...rest }) => {
  let svgPath1 = '';
  let svgPath2 = '';

  // Define SVG paths based on the selected type
  if (name === 'dashboard') {
    svgPath1 =
      'M5.24 0H3.34C1.15 0 0 1.15 0 3.33V5.23C0 7.41 1.15 8.56 3.33 8.56H5.23C7.41 8.56 8.56 7.41 8.56 5.23V3.33C8.57 1.15 7.42 0 5.24 0ZM16.67 0H14.77C12.59 0 11.44 1.15 11.44 3.33V5.23C11.44 7.41 12.59 8.56 14.77 8.56H16.67C18.85 8.56 20 7.41 20 5.23V3.33C20 1.15 18.85 0 16.67 0ZM16.67 11.43H14.77C12.59 11.43 11.44 12.58 11.44 14.76V16.66C11.44 18.84 12.59 19.99 14.77 19.99H16.67C18.85 19.99 20 18.84 20 16.66V14.76C20 12.58 18.85 11.43 16.67 11.43ZM5.24 11.43H3.34C1.15 11.43 0 12.58 0 14.76V16.66C0 18.85 1.15 20 3.33 20H5.23C7.41 20 8.56 18.85 8.56 16.67V14.77C8.57 12.58 7.42 11.43 5.24 11.43Z';
  } else if (name === 'profile') {
    svgPath1 =
      'M7.04 0.000976562C4.42 0.000976562 2.29 2.13098 2.29 4.75098C2.29 7.32098 4.3 9.40098 6.92 9.49098C7 9.48098 7.08 9.48098 7.14 9.49098H7.21C8.43936 9.44996 9.60458 8.93245 10.4593 8.04788C11.314 7.16331 11.7912 5.98102 11.79 4.75098C11.79 2.13098 9.66 0.000976562 7.04 0.000976562ZM12.12 12.15C9.33 10.29 4.78 10.29 1.97 12.15C0.7 13 0 14.15 0 15.38C0 16.61 0.7 17.75 1.96 18.59C3.36 19.53 5.2 20 7.04 20C8.88 20 10.72 19.53 12.12 18.59C13.38 17.74 14.08 16.6 14.08 15.36C14.07 14.13 13.38 12.99 12.12 12.15Z';
  } else if (name === 'project') {
    svgPath1 =
      'M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM5.63 16.15C5.63 16.56 5.29 16.9 4.88 16.9C4.47 16.9 4.13 16.56 4.13 16.15V14.08C4.13 13.67 4.47 13.33 4.88 13.33C5.29 13.33 5.63 13.67 5.63 14.08V16.15ZM10.75 16.15C10.75 16.56 10.41 16.9 10 16.9C9.59 16.9 9.25 16.56 9.25 16.15V12C9.25 11.59 9.59 11.25 10 11.25C10.41 11.25 10.75 11.59 10.75 12V16.15ZM15.87 16.15C15.87 16.56 15.53 16.9 15.12 16.9C14.71 16.9 14.37 16.56 14.37 16.15V9.93C14.37 9.52 14.71 9.18 15.12 9.18C15.53 9.18 15.87 9.52 15.87 9.93V16.15ZM15.87 6.77C15.87 7.18 15.53 7.52 15.12 7.52C14.71 7.52 14.37 7.18 14.37 6.77V5.8C11.8287 8.42175 8.60333 10.2787 5.06 11.16C5 11.18 4.94 11.18 4.88 11.18C4.54 11.18 4.24 10.95 4.15 10.61C4.05 10.21 4.29 9.8 4.7 9.7C8.04433 8.86603 11.0804 7.09298 13.45 4.59H12.2C11.79 4.59 11.45 4.25 11.45 3.84C11.45 3.43 11.79 3.09 12.2 3.09H15.13C15.17 3.09 15.2 3.11 15.24 3.11C15.29 3.12 15.34 3.12 15.39 3.14C15.44 3.16 15.48 3.19 15.53 3.22C15.56 3.24 15.59 3.25 15.62 3.27C15.63 3.28 15.63 3.29 15.64 3.29C15.68 3.33 15.71 3.37 15.74 3.41C15.77 3.45 15.8 3.48 15.81 3.52C15.83 3.56 15.83 3.6 15.84 3.65C15.85 3.7 15.87 3.75 15.87 3.81C15.87 3.82 15.88 3.83 15.88 3.84V6.77H15.87Z';
  } else if (name === 'wallet') {
    svgPath1 =
      'M22.3176 14.8C22.0353 17.9412 19.7647 20 16.4706 20H5.88235C2.63529 20 0 17.3647 0 14.1176V5.88235C0 2.68235 1.92941 0.447059 4.92941 0.0705882C5.23529 0.0235293 5.55294 0 5.88235 0H16.4706C16.7765 0 17.0706 0.0117647 17.3529 0.0588235C20.1647 0.388235 22.0706 2.35294 22.3176 5.2C22.3243 5.28057 22.3139 5.36162 22.2871 5.43791C22.2604 5.5142 22.2179 5.58401 22.1624 5.64281C22.107 5.70161 22.0397 5.74809 21.9651 5.77922C21.8905 5.81035 21.8102 5.82545 21.7294 5.82353H19.9059C18.7765 5.82353 17.7294 6.25882 16.9765 7.03529C16.0824 7.90588 15.6353 9.12941 15.7412 10.3529C15.9294 12.4941 17.8118 14.1765 20.0471 14.1765H21.7294C22.0706 14.1765 22.3529 14.4588 22.3176 14.8Z';
    svgPath2 =
      'M23.5294 8.78789V11.2114C23.5294 11.8585 23.0117 12.3879 22.3529 12.4114H20.047C18.7764 12.4114 17.6117 11.482 17.5059 10.2114C17.4353 9.47024 17.7176 8.77613 18.2117 8.29377C18.647 7.84671 19.247 7.58789 19.9059 7.58789H22.3529C23.0117 7.61142 23.5294 8.14083 23.5294 8.78789Z';
  } else if (name === 'test') {
    svgPath1 =
      'M11.06 0H6.35997C5.31997 0 4.46997 0.84 4.46997 1.88V2.82C4.46997 3.86 5.30997 4.7 6.34997 4.7H11.06C12.1 4.7 12.94 3.86 12.94 2.82V1.88C12.95 0.84 12.1 0 11.06 0Z';
    svgPath2 =
      'M13.95 2.81949C13.95 4.40949 12.65 5.70949 11.06 5.70949H6.36C4.77 5.70949 3.47 4.40949 3.47 2.81949C3.47 2.25949 2.87 1.90949 2.37 2.16949C1.65376 2.55091 1.05479 3.12003 0.637292 3.81587C0.219792 4.5117 -0.000507297 5.30802 8.77183e-07 6.11949V15.5295C8.77183e-07 17.9895 2.01 19.9995 4.47 19.9995H12.95C15.41 19.9995 17.42 17.9895 17.42 15.5295V6.11949C17.42 4.40949 16.46 2.91949 15.05 2.16949C14.55 1.90949 13.95 2.25949 13.95 2.81949ZM9.09 14.9495H4.71C4.3 14.9495 3.96 14.6095 3.96 14.1995C3.96 13.7895 4.3 13.4495 4.71 13.4495H9.09C9.5 13.4495 9.84 13.7895 9.84 14.1995C9.84 14.6095 9.5 14.9495 9.09 14.9495ZM11.71 10.9495H4.71C4.3 10.9495 3.96 10.6095 3.96 10.1995C3.96 9.78949 4.3 9.44949 4.71 9.44949H11.71C12.12 9.44949 12.46 9.78949 12.46 10.1995C12.46 10.6095 12.12 10.9495 11.71 10.9495Z';
  } else if (name === 'support') {
    svgPath1 =
      'M19.176 7.05474C17.2584 7.05474 16.4744 5.69865 17.4279 4.03532C17.9788 3.07123 17.6504 1.84227 16.6863 1.29136L14.8534 0.242508C14.0165 -0.255431 12.9358 0.0412139 12.4379 0.878176L12.3213 1.07947C11.3678 2.7428 9.79987 2.7428 8.83577 1.07947L8.71923 0.878176C8.60503 0.67792 8.45213 0.502379 8.26945 0.361765C8.08677 0.221151 7.87794 0.118274 7.65513 0.0591199C7.43231 -3.42498e-05 7.19996 -0.0142841 6.97159 0.0171996C6.74322 0.0486833 6.52338 0.125272 6.32489 0.242508L4.49205 1.29136C3.52795 1.84227 3.19952 3.08182 3.75044 4.04592C4.71453 5.69865 3.93054 7.05474 2.01295 7.05474C0.911123 7.05474 0 7.95527 0 9.06769V10.9323C0 12.0341 0.900529 12.9453 2.01295 12.9453C3.93054 12.9453 4.71453 14.3013 3.75044 15.9647C3.19952 16.9288 3.52795 18.1577 4.49205 18.7086L6.32489 19.7575C7.16185 20.2554 8.24248 19.9588 8.74042 19.1218L8.85696 18.9205C9.81046 17.2572 11.3784 17.2572 12.3425 18.9205L12.4591 19.1218C12.957 19.9588 14.0377 20.2554 14.8746 19.7575L16.7075 18.7086C17.6715 18.1577 18 16.9182 17.4491 15.9647C16.485 14.3013 17.269 12.9453 19.1866 12.9453C20.2884 12.9453 21.1995 12.0447 21.1995 10.9323V9.06769C21.1939 8.53371 20.9786 8.02334 20.6 7.64673C20.2215 7.27012 19.71 7.05749 19.176 7.05474ZM10.5945 13.4432C8.69805 13.4432 7.15126 11.8964 7.15126 10C7.15126 8.10359 8.69805 6.5568 10.5945 6.5568C12.4909 6.5568 14.0377 8.10359 14.0377 10C14.0377 11.8964 12.4909 13.4432 10.5945 13.4432Z';
  } else if (name === 'find jobs') {
    return (
      <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          id='Vector'
          d='M20.0002 20.0002L15.6572 15.6572M15.6572 15.6572C16.4001 14.9143 16.9894 14.0324 17.3914 13.0618C17.7935 12.0911 18.0004 11.0508 18.0004 10.0002C18.0004 8.9496 17.7935 7.90929 17.3914 6.93866C16.9894 5.96803 16.4001 5.08609 15.6572 4.34321C14.9143 3.60032 14.0324 3.01103 13.0618 2.60898C12.0911 2.20693 11.0508 2 10.0002 2C8.9496 2 7.90929 2.20693 6.93866 2.60898C5.96803 3.01103 5.08609 3.60032 4.34321 4.34321C2.84288 5.84354 2 7.87842 2 10.0002C2 12.122 2.84288 14.1569 4.34321 15.6572C5.84354 17.1575 7.87842 18.0004 10.0002 18.0004C12.122 18.0004 14.1569 17.1575 15.6572 15.6572Z'
          stroke={color}
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  } else if (name === 'logout') {
    svgPath1 =
      'M4.64 10.07C4.64 9.66 4.98 9.32 5.39 9.32H10.87V0.860001C10.8676 0.630917 10.7749 0.412039 10.612 0.250983C10.449 0.0899268 10.2291 -0.000275285 10 6.31134e-07C4.11 6.31134e-07 0 4.11 0 10C0 15.89 4.11 20 10 20C10.47 20 10.86 19.62 10.86 19.14V10.81H5.39C5.29177 10.8121 5.1941 10.7945 5.10279 10.7582C5.01149 10.7219 4.9284 10.6677 4.85845 10.5987C4.78851 10.5297 4.73313 10.4473 4.69561 10.3565C4.65808 10.2657 4.63917 10.1683 4.64 10.07Z';
    svgPath2 =
      'M17.304 9.54036L14.464 6.69036C14.3229 6.55088 14.1324 6.47266 13.934 6.47266C13.7356 6.47266 13.5452 6.55088 13.404 6.69036C13.114 6.98036 13.114 7.46036 13.404 7.75036L14.964 9.31036H10.864V10.8104H14.954L13.394 12.3704C13.104 12.6604 13.104 13.1404 13.394 13.4304C13.544 13.5804 13.734 13.6504 13.924 13.6504C14.114 13.6504 14.304 13.5804 14.454 13.4304L17.294 10.5804C17.594 10.3004 17.594 9.83036 17.304 9.54036Z';
  } else {
    svgPath1 = '';
    svgPath2 = '';
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill={color}
      width={width || '24'}
      height={height || '24'}
      size={size || '24'}
    >
      <path id='Vector' d={svgPath1} />
      <path id='Vector_2' d={svgPath2} />
    </svg>
  );
};

export default Icon;
